<template>
  <div>
    <template>
      <div class="px-3 py-3 flex flex-col width-1414px gap-3">
        <template>
          <SummaryTable
            :headers="headers"
            :items="data.items"
            :footer="data.footer"
            :title="data.title"
            :subtitle="data.subtitle"
            :colspan="7"
            style="box-shadow: none;"
          >
            <template v-slot:header="{ header }">
              <div class="flex justify-start items-center mb-5 gap-2">
                <h1 class="font-bold text-lg leading-6 tracking-wide text-flame">
                  {{ header.title }}
                </h1>
                <h2 class="font-semibold text-base leading-5 tracking-wide text-romanSilver">
                  ({{ header.subtitle }})
                </h2>
              </div>
            </template>
            <template v-slot:item="{ item }">
              <div v-if="item.objectives" class="flex justify-start items-center gap-2">
                <span class="text-darkPurple font-normal text-base leading-5">
                  {{ item.data.objectives }}
                </span>
              </div>
              <div v-if="item.criteria" class="text-darkPurple font-normal text-base leading-5">
                <span class="" v-for="(value, i) in item.data.criteria" :key="`${item}-${i}`">
                  {{ value }};
                </span>
              </div>
              <div v-if="item.weight" class="flex justify-center items-center gap-2 w-18">
                <span class="text-darkPurple font-semibold text-base leading-5">
                  {{ item.data.weight }}%
                </span>
              </div>
              <div v-if="item.session1" class="flex flex-col justify-center items-center">
                <span class="text-darkPurple font-semibold text-base leading-5">
                  {{ item.data.session1.score }}
                </span>
                <span class="text-romanSilver font-black text-xs leading-6 uppercase">
                  Rating = {{ item.data.session1.rating }}
                </span>
              </div>
            </template>
            <template v-slot:footer="{ footer }">
              <div class="flex justify-between items-center px-5">
                  <span class="font-bold text-lg leading-5 text-darkPurple">Total</span>
                  <div class="border-b border-dashed border-romanSilver w-7/12" />
                  <div class="flex justify-between items-center gap-6">
                    <div class="flex flex-col justify-start items-start">
                      <span class="font-black text-xs leading-6 uppercase text-romanSilver">
                        Total Weighted Score
                      </span>
                      <div class="flex justify-start items-center gap-2">
                        <span class="font-bold text-xl leading-5 text-blueCrayola">
                          {{ footer.value }} %
                        </span>
                      </div>
                    </div>
                    <div class="flex flex-col justify-start items-start">
                      <span class="font-black text-xs leading-6 uppercase text-romanSilver">
                        Score (Rel. Weigth: 20%)
                      </span>
                    <span class="font-bold text-xl leading-5 text-blueCrayola">
                      {{ footer.score }}
                    </span>
                    <span class="text-xs text-jet font-semibold leading-5"></span>
                    </div>
                  </div>
                </div>
            </template>
          </SummaryTable>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
  import SummaryTable from "@/components/SummaryTable";

  export default {
    name: "SummaryOutcome",
    components: {
      SummaryTable,
    },
    props: {
      data: {
        type: Object,
        required: true,
        default: (() => {})
      },
    },
    data() {
      return {
        headers: [
          { title: "Review Objectives", value: "objectives" },
          { title: "Success Criteria (KPI)", value: "criteria" },
          { title: "Weight", value: "weight" },
          { title: 'Rating/SCORE (REVIEW SESSION 1)', value: 'session1', width: 17 },
        ],
      };
    },
    methods: {
      percentage(value, total) {
        return (value / total) * 100;
      },
    }
  };
</script>

<style scoped>
  .text-10px {
    font-size: 10px;
  }
  .width-1414px {
    width: 1500px !important;
  }
</style>
