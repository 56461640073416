<template>
  <div class="w-full pt-5 pb-5">
    <template>
      <div class="flex items-center">
        <div class="border-r border-romanSilver h-6 flex">
          <BackButton
            label="Back"
            @onClick="$router.back()"
            variant="secondary"
          />
        </div>
        <h1 class="text-xl text-left font-extrabold mx-3" style="width: 300px">
          Performance Review Session
        </h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </template>

    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div v-else>

    <template>
      <div class="m-3">
          <PreliminaryDetails
            :employee-data="preliminaryData.employeeData"
            :line-manager="preliminaryData.lineManager"
            :performance="preliminaryData.performance"
            :previous-interviews="preliminaryData.previousInterviews"
            :pip-initiation-date="preliminaryData.pipInitiationDate"
            :pip-duration="preliminaryData.pipDuration"
            :interview-days="preliminaryData.previousDays"
            :review-sessions="preliminaryData.reviewSessions"
          />
      </div>
    </template>
    <template>
      <div class="w-full flex justify-center mt-5">
        <div class="w-6/12 border-b border-romanSilver" />
      </div>
    </template>
    <template>
      <div class="m-3 mt-10 flex flex-col gap-5">
        <Tab
          class="mt-6 mx-3"
          :tabs="tabsItems"
          :active-tab="activeTab"
          @currentTab="activeTab = $event"
          :border="true"
        />
        <template v-if="activeTab === 'Review Sessions'">
          <div class="-mt-5">
            <div
              v-for="(value, i) in summary"
              :key="`${value}-${i}`"
              class="mb-2"
            >
              <div class="relative overflow-x-auto shadow">
                <ReviewSessions
                  :data="value"
                  :rating-options="ratingOptions"
                />
              </div>
            </div>
          </div>
        </template>
        <template v-if="activeTab === 'Next Steps'">
          <div class="-mt-5">
            <div
              v-for="(value, i) in reviewNext"
              :key="`${value}-${i}`"
              class="mb-2"
            >
              <div class="relative overflow-x-auto shadow">
                <ReviewNextSteps :data="value" />
              </div>
            </div>
          </div>
        </template>
        <template v-if="activeTab === 'Summary and Outcome'">
          <div class="-mt-5 flex flex-col gap-5">
            <template>
              <Card class="p-2">
                <div class="relative overflow-x-auto">
                  <SummaryOutcome :data="summaryOut" />
                </div>
                <template>
                  <div class="flex justify-between items-center my-3">
                    <div
                      class="border border-romanSilver rounded-md flex justify-between items-center divide-x divide-romanSilver p-2 gap-2"
                    >
                      <div
                        class="flex text-xs font-black leading-5 uppercase gap-2"
                      >
                        <span class="text-romanSilver">Success Criteria:</span>
                        <span class="text-darkPurple"
                          >Min {{ summaryOut.successCriteria }}%</span
                        >
                      </div>
                      <div class="flex justify-between items-center gap-2 pl-2">
                        <div class="flex flex-col items-start justify-start">
                          <span
                            class="font-black text-xs uppercase text-romanSilver"
                          >
                            Final Score
                          </span>
                          <span
                            class="font-normal text-xs uppercase text-romanSilver"
                          >
                            (Weighted Average)
                          </span>
                        </div>
                        <div
                          class="flex flex-col justify-center items-start -my-2"
                        >
                          <div
                            class="flex justify-start items-center gap-1 -my-1"
                          >
                            <span class="font-semibold text-sm text-jet">
                              3.92 of 4.00 -
                            </span>
                            <span class="text-desire text-lg font-bold">
                              98%
                            </span>
                          </div>
                          <span
                            class="font-semibold text-10px uppercase text-darkPurple"
                          >
                            Total Rating = 15 of 16
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="flex justify-between items-center rounded-md p-4 gap-2"
                      :class="
                        summaryOut.outcome === 'Unsuccessful Outcome!'
                          ? `bg-desire`
                          : `bg-mediumSeaGreen`
                      "
                    >
                      <Icon
                        icon-name="icon-flag"
                        size="xs"
                        class="text-ghostWhite font-bold"
                      />
                      <span class="text-ghostWhite font-bold text-lg leading-5">
                        {{ summaryOut.outcome }}
                      </span>
                    </div>
                  </div>
                </template>
              </Card>
            </template>
          </div>
        </template>
      </div>
    </template>
    </div>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import PreliminaryDetails from "../Components/PreliminaryDetails";
import ReviewSessions from "../Components/ReviewSessions";
import ReviewNextSteps from "../Components/ReviewNextSteps";
import SummaryOutcome from "../Components/SummaryOutcome";

export default {
  name: "ReviewSessionDetails",
  components: {
    Tab,
    Card,
    BackButton,
    Breadcrumb,
    PreliminaryDetails,
    ReviewSessions,
    ReviewNextSteps,
    SummaryOutcome,
  },
  data() {
    return {
      loading: true,
      ratingOptions: [],
      managerId: "",
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance Improvement",
          href: "performance-improvement",
          id: "PerformanceImprovement",
        },
        {
          disabled: false,
          text: "Improvement FY2021",
          href: "performance-improvement-FY2021",
          id: "PerformanceImprovementFY2021",
        },
        {
          disabled: false,
          text: "Performance Review Session",
          href: "performance-improvement-plan",
          id: "PerformanceImprovementPlan",
        },
      ],
      tabsItems: ["Review Sessions", "Next Steps", "Summary and Outcome"],
      activeTab: this.$route.query.activeTab || "Review Sessions",
      validateSession: [],
      validateNextStep: [],
      disableBtn: true,
      disableBtnNext: true,
      disableBtnOutcome: true,
      preliminaryData: {
        employeeData: {
          avatar: "",
          name: "",
          id: "",
          designation: "",
          department: "",
          dateEmployed: "",
          supervisoryFunction: "",
        },
        lineManager: {
          avatar: "",
          name: "",
          designation: "",
        },
        performance: [],
      },

      section6Data: [],
      lineManager: [],
      summaryOut: {
        items: [],
        footer: { value: "", score: "" },
        outcome: "",
        successCriteria: "",
      },
      summary: [],
      summaryPreview: [],
      reviewData: [],
      reviewNext: [],
      employeeNextStep: [],
      reviewPayload: [],
      nextStepPayload: [],
      summaryPayload: [],
      disabled: true,
      reviewSessionStructure: {},
      reviewNextStepStructure: {},
      summaryStructure: {
        employeePipId: "",
        signatoryId: "",
        comments: "",
        date: "",
        notify: true,
        signatories: [],
      },
      validatedReview: [],
    };
  },

  methods: {
    percentage(value, total) {
      return (value / total) * 100;
    },

    formatDate(value) {
      let initialDate = "";

      if (value) {
        initialDate = new Date(value).toISOString().replace(/.\d+Z$/g, "");

        return this.$DATEFORMAT(new Date(initialDate), "MMMM dd, yyyy");
      }

      return "";
    },

    formatSecDate(value) {
      const month = this.$DATEFORMAT(new Date(value), "MM");
      const day = this.$DATEFORMAT(new Date(value), "dd");
      const year = this.$DATEFORMAT(new Date(value), "yyyy");

      return `${year}-${month}-${day}`;
    },

    getEmployeePerformance() {
      this.$_getEmployeePreliminary(this.$route.params.id).then((response) => {
        const { userDetails } = response.data;

        this.preliminaryData.employeeData.avatar = userDetails.photo;
        this.preliminaryData.employeeData.name = `${userDetails.fname} ${userDetails.lname}`;
        this.preliminaryData.employeeData.id = "---";
        this.preliminaryData.employeeData.designation = userDetails.designation;
        this.preliminaryData.employeeData.dateEmployed = "---";
        this.preliminaryData.employeeData.department = userDetails.function;
        this.preliminaryData.employeeData.supervisoryFunction =
          userDetails.lineManager.function;
        this.preliminaryData.lineManager.avatar = userDetails.lineManager.photo;
        this.preliminaryData.lineManager.name = `${userDetails.lineManager.fname} ${userDetails.lineManager.lname}`;
        this.preliminaryData.lineManager.designation =
          userDetails.lineManager.designation;

        this.managerId = userDetails.lineManager.managerId;
        this.userId = userDetails.userId;

        const { performanceScore } = response.data;

        performanceScore.map((score, index) => {
          this.preliminaryData.performance.push({
            status: this.setStatus(index, score.score),
            year: `${score.date}`,
            value: score.score,
          });
          return {};
        });
      });
    },

    setStatus(position, score) {
      let status;

      if (position === 0) {
        status = "default";
      }

      if (position > 0) {
        const prevScore = this.preliminaryData.performance[position - 1].value;
        const currScore = score;

        if (currScore > prevScore) {
          status = "increasing";
        } else {
          status = "decreasing";
        }
      }

      return status;
    },

    resetDate(value) {
      if (value) {
        const dateValue = value;

        const split = dateValue.split("T00:00:00.000Z");

        return `${split[0]}T13:00:00.000Z`;
      }
      return "";
    },

    getEmployeePIP() {
      this.$_getEmployeePIP(this.$route.params.id).then((response) => {
        const details = response.data.pipDetails;
        this.preliminaryData.previousInterviews =
          details.hasDonePreviousInterview;
        this.preliminaryData.pipInitiationDate = this.resetDate(
          details.pipInitiationDate
        );
        this.preliminaryData.pipStartDate = this.resetDate(
          details.pipStartDate
        );
        this.preliminaryData.pipEndDate = this.resetDate(details.pipEndDate);
        this.preliminaryData.pipDuration = {
          from: this.resetDate(details.pipStartDate),
          to: this.resetDate(details.pipEndDate),
        };
        this.preliminaryData.previousDays = this.resetDate(
          details.previousInterviewDate
        );

        details.reviewSessionDate.map((session) => {
          // eslint-disable-next-line no-param-reassign
          session.date = this.resetDate(session.date);
          return {};
        });

        this.preliminaryData.reviewSessions = details.reviewSessionDate;

        setTimeout(() => {
          this.setReviewSessionData(details.reviewSessionDate, details);
        }, 1000);

        this.setReviewNextSteps(details.reviewSessionDate, details);

        this.loading = false;
      });
    },

    setCriteria(kpi) {
      const criterias = [];

      kpi.map((item) => {
        criterias.push(item.criteria);

        return {};
      });
      return criterias;
    },

    setReviewSession(dataReview) {
      this.reviewPayload = dataReview;
      this.validateSession = [];

      // eslint-disable-next-line prefer-destructuring
      this.reviewSessionStructure = this.reviewPayload;

      // eslint-disable-next-line no-restricted-syntax
      for (const i in this.reviewPayload[0].reviews) {
        if (
          this.reviewPayload[0].reviews[i].outcome === "" ||
          this.reviewPayload[0].reviews[i].ratingId === ""
        ) {
          this.validateSession.push(false);
        }
        else {
          this.validateSession.push(true);
        }
      }

      if (this.validateSession.every((valid) => valid === true)) {
        this.disableBtn = false;
      }
      else {
        this.disableBtn = true;
      }
    },

    setReviewNextStep(data) {
      this.nextStepPayload = data;
      this.validateNextStep = [];

      // eslint-disable-next-line prefer-destructuring
      this.reviewNextStepStructure = this.nextStepPayload;

      // eslint-disable-next-line no-restricted-syntax
      for (const i in this.nextStepPayload[0].reviews) {
        if (
          this.nextStepPayload[0].reviews[i].comment === ""
        ) {
          this.validateNextStep.push(false);
        }
        else {
          this.validateNextStep.push(true);
        }
      }

      if (this.validateNextStep.every((valid) => valid === true)) {
        this.disableBtnNext = false;
      }
      else {
        this.disableBtnNext = true;
      }
    },

    saveReviews() {
      const payload = {
        reviews: [],
      };

      payload.orgId = this.$orgId;
      payload.employeePipId = this.reviewSessionStructure.employeePipId;
      payload.reviewSessionId = this.reviewSessionStructure.reviewSessionId;
      payload.reviewId = this.reviewSessionStructure.reviewId;

      this.reviewSessionStructure.reviews.map((review) => {
        payload.reviews.push({
          actionPlanId: review.actionPlanId,
          ratingId: review.ratingId,
          outcome: review.outcome,
          documentUrl: review.documentUrl,
        });

        return {};
      });

      const validation = payload.reviews.every(
        (item) => item.outcome && item.ratingId
      );

      if (!validation) {
        this.$toasted.error("Missing Input Fields", { duration: 5000 });
      }

      if (payload.reviewId === "") {
        delete payload.reviewId;
      }

      this.$_setReviewsPIP(payload)
        .then(() => {
          this.$toasted.success("Review PIP set successfully", {
            duration: 5000,
          });
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
        });
    },

    saveNextStep() {
      this.validatedReview = [];
      this.reviewNextStepStructure.orgId = this.$orgId;

      delete this.reviewNextStepStructure.footer;

      delete this.reviewNextStepStructure.subtitle;

      delete this.reviewNextStepStructure.title;

      const newData = this.reviewNextStepStructure.reviews.map(
        ({
          actionPlanId,
          criteria,
          documentUrl,
          objectives,
          outcome,
          ratingId,
          score,
          ...rest
        }) => ({
          ...rest,
        })
      );

      this.reviewNextStepStructure.nextSteps = newData;

      const validation = this.reviewNextStepStructure.nextSteps.every(
        (item) => item.comment
      );

      if (validation) {
        if (this.reviewNextStepStructure.reviewId === "") {
          delete this.reviewNextStepStructure.reviewId;
        }

        this.$_setEmployeeNextStep(this.reviewNextStepStructure)
          .then((response) => {
            this.$toasted.success(response.data.message, {
              duration: 5000,
            });
          })
          .catch((error) => {
            this.$toasted.error(error.response.data.message, {
              duration: 5000,
            });
          });
      } else {
        this.$toasted.error("Missing Input Fields", { duration: 5000 });
      }
    },

    setReviewSessionData(reviewSessions, details) {
      reviewSessions.map((sessions, index) => {
        let title;
        const currentDate = Date.parse(this.formatSecDate(new Date()));
        const timeline = Date.parse(sessions.date);

        const date1 = new Date();
        const date2 = new Date(sessions.date);

        const timeDiff = date2.getTime() - date1.getTime();

        const daysDiff = timeDiff / (1000 * 3600 * 24);

        if (currentDate > timeline) {
          title = "Current";
        } else {
          title = `Starts in ${Math.round(daysDiff)} days`;
        }

        this.summary.push({
          title: `Review Session ${index + 1}`,
          subtitle: `${title}`,
          reviewSessionId: sessions.reviewSessionId,
          employeePipId: details.id,
          reviewId: this.setId(sessions.reviewSessionId),
          reviews: this.setReviews(sessions.reviewSessionId, details),
          footer: this.setFooter(sessions.reviewSessionId),
        });

        return {};
      });
    },

    setEmployeeReviews() {
      this.$_getEmployeeReviews(this.$route.params.id).then((response) => {
        this.reviewData = response.data.review;
      });
    },

    setReviews(sessionId, details) {
      const reviewPayload = [];

      if (this.reviewData.length > 0) {
        this.reviewData.filter((review) => {
          if (review.reviewSessionId === sessionId) {
            details.employee_improvements_task.map((task, i) => {
              reviewPayload.push({
                actionPlanId: task.id,
                objectives: task.objective,
                criteria: this.setCriteria(
                  review.review_scores[i].pip_task.criterias
                ),
                outcome: review.review_scores[i].evaluationOutcome,
                documentUrl: "",
                score: review.review_scores[i].score,
                ratingId: this.setRating(
                  review.review_scores[i].rating,
                  this.ratingOptions
                ),
              });
              return {};
            });
          } else {
            details.employee_improvements_task.map((task) => {
              reviewPayload.push({
                actionPlanId: task.id,
                objectives: task.objective,
                criteria: this.setCriteria(task.criterias),
                outcome: "",
                documentUrl: "",
                score: "",
                ratingId: "",
              });
              return {};
            });
          }

          return {};
        });
      } else {
        details.employee_improvements_task.map((task) => {
          reviewPayload.push({
            actionPlanId: task.id,
            objectives: task.objective,
            criteria: this.setCriteria(task.criterias),
            outcome: "",
            documentUrl: "",
            score: "",
            ratingId: "",
          });
          return {};
        });
      }

      return reviewPayload;
    },

    setFooter(sessionId) {
      const footer = {};

      if (this.reviewData.length > 0) {
        this.reviewData.filter((review) => {
          if (review.reviewSessionId === sessionId) {
            footer.score = review.score;
          } else {
            footer.score = 0;
          }

          return {};
        });
      } else {
        footer.score = 0;
      }

      this.isLoading = false;

      return footer;
    },

    setId(sessionId) {
      let reviewId = "";

      if (this.reviewData.length > 0) {
        this.reviewData.filter((review) => {
          if (review.reviewSessionId === sessionId) {
            reviewId = review.id;
          } else {
            reviewId = "";
          }

          return {};
        });
      } else {
        reviewId = "";
      }

      this.isLoading = false;

      return reviewId;
    },

    setReviewNextSteps(reviewSessions, details) {
      reviewSessions.map((sessions) => {
        this.$_getEmployeeReviews(this.$route.params.id).then((response) => {
          const reviewData = response.data.review;
          if (reviewData.length > 0) {
            reviewData.filter((review) => {
              if (review.reviewSessionId === sessions.reviewSessionId) {
                this.reviewNext.push({
                  orgId: "",
                  employeePipId: details.id,
                  reviews: [],
                  nextSteps: [],
                  footer: { score: review.score },
                });

                this.reviewNext.forEach((next) => {
                  details.employee_improvements_task.map((task, i) => {
                    next.reviews.push({
                      actionPlanId: task.id,
                      objectives: task.objective,
                      criteria: this.setCriteria(task.criterias),
                      outcome: review.review_scores[i].evaluationOutcome,
                      documentUrl: "",
                      pipTaskId: task.id,
                      comment: this.setNextStepComment(task.id),
                      score: review.review_scores[i].score,
                      ratingId: review.review_scores[i].rating,
                    });
                    return {};
                  });
                });
              }

              return {};
            });
          }
        });

        return {};
      });
    },

    setRating(rating, ratingOptions) {
      let id;
      ratingOptions.filter((options) => {
        if (options.name === rating) {
          id = options.id;
        }
        return {};
      });
      return id || "";
    },

    getNextStep() {
      this.$_getEmployeeNextStep(this.$route.params.id).then((response) => {
        this.employeeNextStep = response.data.EmployeeNextStep;
      });
    },

    setNextStepComment(taskId) {
      let comments;

      this.employeeNextStep.filter((nextStep) => {
        if (nextStep.pipTaskId === taskId) {
          comments = nextStep.nextStep;
        }
        return {};
      });

      return comments;
    },

    getEmployeeSummary() {
      this.$_getEmployeeSummary(this.$route.params.id).then((result) => {
        const sessions = result.data.reviewsSessions;
        let sum = 0;

        sessions.forEach((session) => {
          this.summaryOut.employeePipId = session.employeePipId;
          this.summaryStructure.employeePipId = session.employeePipId;

          session.review_scores.forEach((data) => {
            this.summaryOut.items.push({
              objectives: data.pip_task.objective,
              criteria: this.setCriteria(data.pip_task.criterias),
              weight: data.pip_task.weight,
              session1: { rating: data.rating, score: data.score },
            });

            sum += data.pip_task.weight;

            this.summaryOut.footer.value = sum;
            this.summaryOut.footer.score = session.score;
          });
        });

        this.summaryOut.outcome = result.data.outcome;
        this.summaryOut.successCriteria = result.data.successCriteria;
      });
    },

    getPIPSignatures() {
      this.$_getPIPSignatures(this.$route.query.pip)
        .then((response) => {
          this.pipSignatures = response.data.data.reviewSignatures;
          this.getSignatories();
        })
        .catch((err) => {
          this.getSignatories();
          return err;
        });
    },

    setSignatureDetails(userId, type) {
      let signature;
      let comments;
      let date;
      let idValue;

      if (type === "signature") {
        if (this.pipSignatures) {
          this.pipSignatures.filter((signatory) => {
            if (signatory.userId === userId) {
              signature = this.$getInitials(
                `${signatory.fname} ${signatory.lname}`
              );
            }
            return {};
          });
        }
        return signature || "";
      }

      if (type === "comments") {
        if (this.pipSignatureDetails) {
          this.pipSignatureDetails.filter((signatory) => {
            if (signatory.signatoryId === userId) {
              comments = signatory.comments;
            }
            return {};
          });
        }
        return comments || "";
      }

      if (type === "date") {
        if (this.pipSignatureDetails) {
          this.pipSignatureDetails.filter((signatory) => {
            if (signatory.signatoryId === userId) {
              date = signatory.date;
            }
            return {};
          });
        }
        return date || "";
      }

      if (type === "id") {
        if (this.pipSignatureDetails) {
          this.pipSignatureDetails.filter((signatory) => {
            if (signatory.signatoryId === userId) {
              idValue = signatory.id;
            }
            return {};
          });
        }
        return idValue || "";
      }

      return "";
    },

    getSignatories() {
      this.$_getPIPSignatories(
        this.$route.params.tmpId,
        "?signatory=review"
      ).then((response) => {
        const signatories = response.data.data;

        signatories.filter((item) => {
          if (item.userId !== this.managerId) {
            this.section6Data.push({
              id: this.setSignatureDetails(item.userId, "id"),
              photo: item.photo,
              name: `${item.fname} ${item.lname}`,
              position: item.designation,
              signature: this.setSignatureDetails(item.userId, "signature"),
              userId: item.userId,
              comments: this.setSignatureDetails(item.userId, "comments"),
              date: this.setSignatureDetails(item.userId, "date"),
            });
          }
          return {};
        });

        this.section6Data.push({
          id: this.setSignatureDetails(this.managerId, "id"),
          photo: this.preliminaryData.lineManager.avatar,
          name: this.preliminaryData.lineManager.name,
          position: this.preliminaryData.lineManager.designation,
          signature: this.setSignatureDetails(this.managerId, "signature"),
          userId: this.managerId,
          comments: this.setSignatureDetails(this.managerId, "comments"),
          date: this.setSignatureDetails(this.managerId, "date"),
        });
      });
    },

    setOutcomePayload(value) {
      this.summaryStructure.signatories = [];
      value.filter((item) => {
        if (item.signature !== "") {
          this.summaryStructure.signatoryId = item.userId;
          this.summaryStructure.comments = item.comments;
          this.summaryStructure.date = item.date;

          if (item.comments !== '') {
            this.disableBtnOutcome = false;
          }

          const payload = {
            employeePipId: this.summaryStructure.employeePipId,
            signatoryId: item.userId,
            comments: item.comments,
            date: item.date,
          };

          this.$_signPIPReview(payload)
            .then((response) => {
              this.$toasted.success(response.data.message, { duration: 5000 });
            })
            .catch((err) => {
              this.$toasted.error(err.response.data.message, {
                duration: 5000,
              });
            });
        }
        this.summaryStructure.signatories.push(item.userId);
        return {};
      });
    },

    saveSummary() {
      const payload = this.summaryStructure;

      this.$_setSummaryOutcome(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },
  },

  mounted() {
    this.getEmployeePerformance();
    this.getEmployeePIP();
    this.setEmployeeReviews();
    this.$_getPerfomanceRating().then((response) => {
      response.data.PerformanceRatings.map((ratings) => {
        this.ratingOptions.push({
          id: ratings.id,
          name: ratings.rating,
        });
        return {};
      });
    });
    this.getNextStep();
    this.getEmployeeSummary();
    this.getPIPSignatures();
  },
};
</script>

<style scoped>
.submitted {
  background: rgba(19, 181, 106, 0.15);
  color: #27ae60;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}
.not_submitted {
  background: rgba(234, 60, 83, 0.15);
  color: #ea3c53;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}
</style>
