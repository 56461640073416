<template>
  <Card class="px-3 py-3 flex flex-col width-1414px gap-3">
    <template>
      <SummaryTable
        :headers="headers"
        :items="data.reviews"
        :footer="data.footer"
        :title="data.title"
        :subtitle="data.subtitle"
        :colspan="6"
        :header="{}"
        :subheaders="{}"
      >
        <template v-slot:header="{ header }">
          <div class="flex justify-start items-center mb-5 gap-2">
            <h1 class="font-bold text-lg leading-6 tracking-wide text-flame">
              {{ header.title }}
            </h1>
            <h2
              class="font-semibold text-base leading-5 tracking-wide text-blueCrayola"
              v-if="header.subtitle === 'Current'"
            >
              ({{ header.subtitle }})
            </h2>
            <h2
              class="font-semibold text-base leading-5 tracking-wide text-romanSilver"
              v-else
            >
              ({{ header.subtitle }})
            </h2>
          </div>
        </template>
        <template v-slot:item="{ item }">
          <div v-if="item.objectives" class="flex justify-start items-center gap-2">
            <span class="text-darkPurple font-normal text-base leading-5">
              {{ item.data.objectives }}
            </span>
          </div>
          <div v-if="item.criteria" class="text-darkPurple font-normal text-base leading-5">
            <span class="" v-for="(value, i) in item.data.criteria" :key="`${item}-${i}`">
              {{ value }};
            </span>
          </div>
          <div v-if="item.outcome" class="flex justify-start items-center gap-2">
            <span class="text-darkPurple font-normal text-base leading-5" v-if="item.data.outcome !== ''">
              {{ item.data.outcome }}
            </span>
            <span class="text-darkPurple font-normal text-base leading-5" v-else>
              ---
            </span>
          </div>
          <div v-if="item.documentUrl" class="flex justify-center items-center gap-2">
            <div class="text-flame text-base">
              <Icon icon-name="attachment" size="xs" />
            </div>
            <span class="text-flame text-xs font-black leading-5 uppercase">
              VIEW
            </span>
          </div>
          <div v-if="item.score" class="flex flex-col">
            <span class="text-darkPurple font-semibold text-base leading-5">
              {{ item.data.score }}
            </span>
            <span class="text-romanSilver font-black text-xs leading-6 uppercase" v-if="item.data.ratingId !== ''">
              Total Rating = {{ formatRating(item.data.ratingId) }}
            </span>
            <span class="text-romanSilver font-black text-xs leading-6 uppercase" v-else>
              ---
            </span>
          </div>
        </template>
        <template v-slot:footer="{ footer }">
          <div class="flex justify-between items-center px-5">
              <span class="font-bold text-lg leading-5 text-darkPurple">Total</span>
              <div class="border-b border-dashed border-romanSilver w-7/12" />
              <div class="flex justify-between items-center gap-6">
                <div class="flex flex-col justify-start items-start">
                  <span class="font-black text-xs leading-6 uppercase text-romanSilver">
                    Total Weighted Score
                  </span>
                  <div class="flex justify-start items-center gap-2">
                    <span class="font-bold text-xl leading-5 text-blueCrayola">
                      {{ footer.score }}
                    </span>
                    <span class="text-xs text-jet font-semibold leading-5">of 4</span>
                  </div>
                </div>
                <div class="flex flex-col justify-start items-start">
                  <span class="font-black text-xs leading-6 uppercase text-romanSilver">
                    % Score
                  </span>
                  <span class="font-bold text-xl leading-5 text-mediumSeaGreen">
                  </span>
                </div>
              </div>
            </div>
        </template>
      </SummaryTable>
    </template>
  </Card>
</template>

<script>
  import Card from "@scelloo/cloudenly-ui/src/components/card";
  import SummaryTable from "@/components/SummaryTable";

  export default {
    name: "ReviewSessions",
    components: {
      Card,
      SummaryTable
    },
    props: {
      data: {
        type: Object,
        required: true,
        default: (() => {})
      },
      ratingOptions: {
      type: Array,
      required: true,
      default: () => [],
    },
    },
    data() {
      return {
        headers: [
        { title: "Review Objectives", value: "objectives" },
        { title: "Success Criteria (KPI)", value: "criteria" },
        { title: "Evaluation Outcome", value: "outcome", width: 18 },
        { title: "Supporting Document(s)", value: "documentUrl", width: 15 },
        { title: "Score", value: "score", width: 10 },
        ],
      };
    },
    methods: {
      percentage(value, total) {
        return (value / total) * 100;
      },

      formatRating(ratingId) {
        let value;

        this.ratingOptions.filter((rating) => {
          if (rating.id === ratingId) {
            value = rating.name;
          };
          return {};
        });

        return value;
      }
    }
  };
</script>

<style scoped>
  .text-10px {
    font-size: 10px;
  }
  .width-1414px {
    width: 1414px !important;
  }
</style>
